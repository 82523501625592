import "../../../../styles/variables.scss";

export const customStylesCan = {
  head: {
    style: {
      borderBottom: "3px solid rgba(0, 0, 0, 0.12)",
    },
  },
  headRow: {
    style: {
      backgroundColor: "none",
      borderBottom: "none",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#FFF",
      fontSize: "14px",
      borderTop: "3px solid rgba(0, 0, 0, 0.12)",
    },
  },
  rows: {
    style: (row: any, index: number) => ({
      background: "$Color13",
      minHeight: "50px",
      borderTop: index === 0 ? "3px solid rgba(0, 0, 0, 0.12)" : "none",
    }),
  },
  cells: {
    style: {
      fontColor: "#1A141F",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: "14px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "none",
      background: "rgba(245, 243, 247, 0.4)",
    },
  },
  pagination: {
    style: {
      minHeight: "50px",
      fontWeight: "400",
      fontSize: "13px",
    },
    pageButtonsStyle: {
      transition: "0.2s",
    },
  },
};
