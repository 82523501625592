import React, { useState } from "react";
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";
import "./LogModule.scss";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { ModuleConnectionDTO, VehicleDTO } from "../../../api/auth/apiClient";
import { useIntl } from "react-intl";
import DataTable, { TableColumn } from "react-data-table-component";
import { ReactComponent as BtnUpdate } from "../buttons/svg/resetButton.svg";
import { ReactComponent as DeleteBtn } from "./svg/delete.svg";
import { conditionalRowStyles, customStyles } from "./stylesForLogModule";
import { green } from "@mui/material/colors";
import ModalNotification from "../../CreateTransport/Modal_notification/ModalNotification";

type LogModuleProps = {
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  activeTransport: VehicleDTO;
  logModule: Array<ModuleConnectionDTO> | undefined;
  getLogModule: (uvi: string) => void;
};

function LogModule(props: LogModuleProps) {
  const intl = useIntl();
  const [showDeleteLogModal, setShowDeleteLogModal] = useState<boolean>(false);

  async function clearLogModule(uvi: string) {
    try {
      await mapClient.clearConnectionLogs(uvi);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "clearLogModuleSuccess" }),
      });
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "clearLogModuleError" }),
      });
    }
    await props.getLogModule(props.activeTransport.uvi!);
  }

  async function refreshLogModule(uvi: string) {
    try {
      await props.getLogModule(uvi);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "updateLogModuleSuccess" }),
      });
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "updateLogModuleError" }),
      });
    }
  }

  const columns: TableColumn<ModuleConnectionDTO>[] = [
    {
      name: intl.formatMessage({ id: "log_table_date" }),
      selector: (log) =>
        log.date?.toLocaleString("ru", {
          timeZone: "UTC",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
    },
    {
      name: intl.formatMessage({ id: "log_table_status" }),
      selector: (log) => (log.connected ? "Online" : "Offline"),
      cell: (row) => (
        <div style={{ color: row.connected ? green[500] : "" }}>
          {row.connected ? "Online" : "Offline"}
        </div>
      ),
    },
  ];

  return (
    <div>
      {showDeleteLogModal ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({ id: "deleteLogQuestion" })}
            onYesModalActions={() => setShowDeleteLogModal(false)}
            onNoModalActions={() => clearLogModule(props.activeTransport.uvi!)}
          />
        </div>
      ) : null}
      <Expander type="multiple">
        <ExpanderItem
          title={intl.formatMessage({ id: "connection_history" })}
          value={"item"}
        >
          <div className="logModuleContainer flex">
            <div className="logModuleTable">
              <DataTable
                columns={columns}
                data={props.logModule!}
                noDataComponent={intl.formatMessage({ id: "empty_table" })}
                fixedHeaderScrollHeight="450px"
                pointerOnHover
                persistTableHead
                highlightOnHover
                fixedHeader
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
            <div className="flex flex-col ml-5 mr-8">
              <button
                type="button"
                className="btn"
                onClick={async () =>
                  refreshLogModule(props.activeTransport.uvi!)
                }
              >
                <BtnUpdate />
                <span className="ml-2">
                  {intl.formatMessage({ id: "update" })}
                </span>
              </button>
              <button
                type="button"
                className="clear mt-5"
                onClick={() => setShowDeleteLogModal(true)}
              >
                <DeleteBtn />
                <span className="ml-2">
                  {intl.formatMessage({ id: "button_clear" })}
                </span>
              </button>
            </div>
          </div>
        </ExpanderItem>
      </Expander>
    </div>
  );
}

export default LogModule;
