import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as BtnReset } from "./svg/resetButton.svg";

type ButtonResetModuleProps = {
  setShowModalReset: (value: boolean) => void;
};

function ButtonResetModule(props: ButtonResetModuleProps) {
  return (
    <button
      type="button"
      className="btn ml-5"
      onClick={() => props.setShowModalReset(true)}
    >
      <BtnReset />
      <span className="ml-2.5">
        <FormattedMessage id="restart_button" />
      </span>
    </button>
  );
}

export default ButtonResetModule;
