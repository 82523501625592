import React from "react";
import { ReactComponent as BtnBack } from "./svg/arrow.svg";

type PropsButtonBack = {
  buttonActive: boolean;
  onClickButtonBack: (showModal: boolean) => void;
};
function ButtonBack(props: PropsButtonBack) {
  return (
    <button
      type="button"
      className={`back mr-5 ${props.buttonActive ? "active" : ""}`}
      onClick={() => props.onClickButtonBack(true)}
    >
      <BtnBack />
    </button>
  );
}

export default ButtonBack;
