import React from "react";
import { Marker, Popup } from "react-leaflet";
import { VehicleDTO } from "../../../api/auth/apiClient";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

type PlacemarkListProps = {
  carInfo: Array<VehicleDTO>;
};

const PlacemarkList: React.FC<PlacemarkListProps> = ({ carInfo }) => {
  return (
    <>
      {carInfo
        .filter(
          (f) =>
            f.coordinates?.latitude !== undefined &&
            f.coordinates?.longitude !== undefined
        )
        .map((item) => {
          const latitude = item.coordinates?.latitude ?? 0;
          const longitude = item.coordinates?.longitude ?? 0;

          return (
            <Marker
              key={item.id}
              position={[latitude, longitude]}
              icon={L.icon({
                iconUrl: "./image/truckIcons/iconBus.svg",
                iconSize: [40, 40],
              })}
            >
              <Popup>{item.name}</Popup>
            </Marker>
          );
        })}
    </>
  );
};

export default PlacemarkList;
