import React from "react";
import "./Header.scss";
import LocaleSelector from "../LocaleSelector/LocaleSelector";
import { NavLink } from "react-router-dom";
import { HeaderPropsType } from "./HeaderTypes";

function Header(props: HeaderPropsType) {
  return (
    <header>
      <div className="headerItems flex justify-between">
        <div className="headerLogo">
          <NavLink to="/">
            <img src="/image/header/logoHeader.svg" alt="Logo" />
          </NavLink>
        </div>
        <div className="headerSelector">
          <LocaleSelector
            currentLocale={props.currentLocale}
            changeCurrentLocale={props.changeCurrentLocale}
          />
        </div>
      </div>
    </header>
  );
}
export default Header;
