import React from "react";
import { Switch } from "@headlessui/react";
import "./Toggle.scss";
import { FormattedMessage } from "react-intl";

type ToggleTypeProps = {
  hexSelected: boolean;
  setHexEnabled: (hexSelected: boolean) => void;
};

function Toggle(props: ToggleTypeProps) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="mr-4">
          <span className="label_title">
            <FormattedMessage id="toggle_text" />
          </span>
        </Switch.Label>
        <Switch
          checked={props.hexSelected}
          onChange={props.setHexEnabled}
          className={`${
            props.hexSelected ? "bg-blue-800" : "bg-gray-200"
          } relative inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            className={`${
              props.hexSelected ? "translate-x-4" : "translate-x-0"
            } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}

export default Toggle;
