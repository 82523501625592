import React from "react";
import { FormattedMessage } from "react-intl";
import "./ModalNotification.scss";

type PropsTypeModalNotification = {
  onYesModalActions: () => void;
  onNoModalActions: () => void;
  modalQuestion: string;
};

function ModalNotification(props: PropsTypeModalNotification) {
  return (
    <div className="modal">
      <div className="modalContainer">
        <div className="modalForm">
          <h2>{props.modalQuestion}</h2>
          <div className="modalButtons">
            <button onClick={props.onNoModalActions}>
              <span>
                <FormattedMessage id="buttonYes" />
              </span>
            </button>
            <button onClick={props.onYesModalActions}>
              <span>
                <FormattedMessage id="buttonNo" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNotification;
