export const DataHistoryPeriod = [
  { label: "1 секунда", value: 1 },
  { label: "5 секунд", value: 5 },
  { label: "10 секунд", value: 10 },
  { label: "20 секунд", value: 20 },
  { label: "30 секунд", value: 30 },
  { label: "1 минуту", value: 60 },
  { label: "10 минут", value: 600 },
  { label: "30 минут", value: 1800 },
  { label: "1 час", value: 3600 },
  { label: "4 часа", value: 1400 },
  { label: "12 часов", value: 43200 },
  { label: "24 часа", value: 86400 },
  { label: "За все время", value: 1000000000 },
];
