export const userPageStyles = {
  headCells: {
    style: {
      backgroundColor: "#FFF",
      fontSize: "14px",
    },
  },
  rows: {
    style: {
      background: "#f5f3f7",
    },
  },
  cells: {
    style: {
      fontColor: "#1A141F",
      fontWeight: "300",
      fontStyle: "bold",
      fontSize: "14px",
      minHeight: "50px",
    },
  },
};
