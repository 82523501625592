import React from "react";
import "../Login.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { EntryPageViewState } from "../LoginTypes";
import { SubmitHandler, useForm } from "react-hook-form";
import { ResetPasswordEmail, ResponseDTO } from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";

type PropsResetForm = {
  changeView: (view: EntryPageViewState) => void;
  onChangeNotificationState: (state: NotificationState) => void;
};
function ResetForm(props: PropsResetForm) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordEmail>({ mode: "onTouched" });
  const onSubmit: SubmitHandler<ResetPasswordEmail> = async (data) => {
    let response = new ResponseDTO();
    try {
      response = await mapClient.sendResetPasswordEmail(
        new ResetPasswordEmail(data)
      );
    } catch (error) {
      console.error("Error sending reset password email:", error);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "error_send_letter" }),
      });
      return;
    }
    if (response.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "send_letter" }),
      });
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "error_send_letter" }),
      });
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>
          <FormattedMessage id="PWReset" />
        </h2>
        <span>
          <FormattedMessage id="PWReset_reset_link" />
        </span>
      </div>
      <fieldset>
        <ul>
          <li>
            <label htmlFor="email">
              <FormattedMessage id="PWReset_email" />:
            </label>
            <input
              {...register("email", {
                required: intl.formatMessage({ id: "input_required" }),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: intl.formatMessage({ id: "validate_email" }),
                },
              })}
              type="email"
              id="email"
            />
            <div className="mt-1">
              {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
            </div>
          </li>
        </ul>
      </fieldset>
      <button type="submit">
        <FormattedMessage id="PWReset_reset_password" />
      </button>
      <button
        className="mb-8"
        type="button"
        onClick={() => props.changeView(EntryPageViewState.logIn)}
      >
        <FormattedMessage id="PWReset_go_back" />
      </button>
    </form>
  );
}

export default ResetForm;
