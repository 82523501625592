import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import "./CreateTransport.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as BtnSve } from "./buttons/svg/Save.svg";
import ModalNotification from "./Modal_notification/ModalNotification";
import { ReactComponent as ClearInput } from "./buttons/svg/clearInput.svg";
import { VehicleDTO } from "../../api/auth/apiClient";
import { mapClient } from "../../api/auth/AxiosInstanse";
import PhoneInput from "react-phone-number-input/input";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";
import ArchivedTransport from "./Form_archived_transport/ArchivedTransport";
import Notification from "../Notification/Notification";

type PropsNewTransportProps = {
  onShowCreateTransport: (showCreateTransport: boolean) => void;
  onClickButtonAddNewTransport: (transportActiveClick: boolean) => void;
  getTransport: () => void;
  onChangeNotificationState: (state: NotificationState) => void;
  onCloseModalActions: () => void;
  showNotification: NotificationState;
};

function CreateTransport(props: PropsNewTransportProps) {
  const intl = useIntl();
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [archivedVehicle, setArchivedVehicle] = useState(new VehicleDTO());
  const [enteredVehicle, setEnteredVehicle] = useState<VehicleDTO>();
  const [showFormArchivedVehicle, setShowModalFormArchivedVehicle] =
    useState(false);

  const {
    register,
    formState: { errors },
    setFocus,
    resetField,
    handleSubmit,
    control,
  } = useForm<VehicleDTO>({ mode: "onSubmit" });

  React.useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const onSubmit: SubmitHandler<VehicleDTO> = async (data) => {
    let resultVehicle;
    if (data.module?.imei === "") {
      try {
        await mapClient.createVehicle({
          ...data,
          module: {
            ...data.module,
            imei: data.module.imei === "" ? undefined : data.module.imei,
          },
        } as VehicleDTO);
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "createTransportSuccess" }),
        });
      } catch (error: any) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "createTransportError" }),
        });
      }
      await props.getTransport();
      props.onShowCreateTransport(false);
    } else {
      if (data.module?.imei) {
        try {
          resultVehicle = await mapClient.getVehicleByImei(data.module.imei);
        } catch (error: any) {
          console.error(
            "An error occurred while fetching vehicle by IMEI:",
            error
          );
        }
      }

      if (resultVehicle?.succeeded && resultVehicle?.data?.archived) {
        setArchivedVehicle(resultVehicle.data);
        setEnteredVehicle(data);
        setShowModalFormArchivedVehicle(true);
      } else if (resultVehicle?.succeeded && !resultVehicle?.data?.archived) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "duplicate_vehicle" }),
        });
      } else {
        try {
          await mapClient.createVehicle({
            ...data,
            module: {
              ...data.module,
              imei: data.module?.imei === "" ? undefined : data.module?.imei,
            },
          } as VehicleDTO);
          props.onChangeNotificationState({
            isShow: true,
            type: NotificationType.info,
            message: intl.formatMessage({ id: "createTransportSuccess" }),
          });
        } catch (error: any) {
          props.onChangeNotificationState({
            isShow: true,
            type: NotificationType.error,
            message: intl.formatMessage({ id: "createTransportError" }),
          });
        }
        await props.getTransport();
        props.onShowCreateTransport(false);
      }
    }
  };

  const handleClickTransport = () => resetField("name");
  const handleClickImei = () => resetField("module.imei");
  const handleClickNumber = () => resetField("module.sim.phoneNumber");
  const handleClickPin = () => resetField("module.sim.pin");

  async function restoreArchivedVehicle() {
    try {
      await mapClient.updateVehicle({
        ...archivedVehicle,
        module: {
          ...archivedVehicle.module,
        },
        archived: false,
      } as VehicleDTO);
    } catch (error: any) {
      console.error(
        "An error occurred while restoring archived vehicle:",
        error
      );
    }
    await props.getTransport();
    setShowModalFormArchivedVehicle(false);
    props.onShowCreateTransport(false);
  }

  async function rewriteArchivedVehicle() {
    try {
      await mapClient.updateVehicle({
        ...enteredVehicle,
        module: {
          ...archivedVehicle.module,
        },
        archived: false,
        id: archivedVehicle.id,
      } as VehicleDTO);
    } catch (error: any) {
      console.error(
        "An error occurred while rewriting archived vehicle:",
        error
      );
    }
    await props.getTransport();
    setShowModalFormArchivedVehicle(false);
    props.onShowCreateTransport(false);
  }

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalNotification(false)}
            onNoModalActions={props.onCloseModalActions}
            modalQuestion={intl.formatMessage({
              id: "createTransportQuestion",
            })}
          />
        </div>
      ) : null}
      {showFormArchivedVehicle ? (
        <div>
          <ArchivedTransport
            archivedVehicle={archivedVehicle}
            restoreArchivedVehicle={restoreArchivedVehicle}
            rewriteArchivedVehicle={rewriteArchivedVehicle}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="transportFormContainer">
          <div className="transportForm">
            <div className="formHeader">
              <h2>
                <FormattedMessage id="transportTitle" />
              </h2>
              <img
                onClick={() => setShowModalNotification(true)}
                src="/image/btn-remove/btn-remove.svg"
                alt="Close form"
              />
            </div>
            <div className="formDescription">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="descriptionBlock"
              >
                <label htmlFor="name" className="mb-1">
                  <FormattedMessage id="nameTransport" />
                </label>
                <div className="text-field__icon">
                  <input
                    className={`${errors?.name?.message ? "input_field" : ""}`}
                    {...register("name", {
                      required: intl.formatMessage({ id: "input_required" }),
                    })}
                    type="text"
                    id="text"
                    placeholder={intl.formatMessage({
                      id: "placeholder_name_transport",
                    })}
                  />
                  <button
                    onClick={handleClickTransport}
                    type="button"
                    className="text-field__img"
                  >
                    <ClearInput />
                  </button>
                </div>
                <div className="mt-0.5">
                  {errors?.name && (
                    <p>{errors?.name?.message || "Errors!!!"}</p>
                  )}
                </div>
                <label htmlFor="imei" className="mt-1 mb-1">
                  <FormattedMessage id="transportImei" />
                </label>
                <div className="text-field__icon">
                  <input
                    {...register("module.imei")}
                    type="text"
                    id="text"
                    placeholder={intl.formatMessage({
                      id: "placeholder_imei_transport",
                    })}
                  />
                  <button
                    onClick={handleClickImei}
                    type="button"
                    className="text-field__img"
                  >
                    <ClearInput />
                  </button>
                </div>
                <label htmlFor="simPhoneNumber" className="mt-1 mb-1">
                  <FormattedMessage id="simNumber" />
                </label>
                <div className="text-field__icon">
                  <Controller
                    name="module.sim.phoneNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        onChange={onChange}
                        value={value}
                        placeholder={intl.formatMessage({
                          id: "placeholder_phone_number",
                        })}
                      />
                    )}
                  ></Controller>
                  <button
                    onClick={handleClickNumber}
                    type="button"
                    className="text-field__img"
                  >
                    <ClearInput />
                  </button>
                </div>
                <label className="mt-1 mb-1">
                  <FormattedMessage id="simPin" />
                </label>
                <div className="text-field__icon">
                  <input
                    {...register("module.sim.pin")}
                    placeholder={intl.formatMessage({
                      id: "placeholder_simPin",
                    })}
                  />
                  <button
                    onClick={handleClickPin}
                    type="button"
                    className="text-field__img"
                  >
                    <ClearInput />
                  </button>
                </div>
                <div className="flex mt-5">
                  <div className="m-0">
                    <button type="submit" className="btn ml-auto">
                      <BtnSve />
                      <span className="ml-2.5">
                        <FormattedMessage id="save" />
                      </span>
                    </button>
                  </div>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Notification
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      </div>
    </>
  );
}

export default CreateTransport;
