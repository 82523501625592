import React from "react";
import { FormattedMessage } from "react-intl";
import "./ModalNotification.scss";

type ModalNotificationTypeProps = {
  onClose: (showModalNotification: boolean) => void;
  onShowChangePassword: (showModalChangePassword: boolean) => void;
};

function ModalNotification(props: ModalNotificationTypeProps) {
  function onCloseModal() {
    props.onShowChangePassword(false);
  }
  function onCloseModalNotification() {
    props.onClose(false);
  }
  return (
    <div className="modalChangePassword">
      <div className="modalContainerPassword">
        <div className="modalForm">
          <h2>
            <FormattedMessage id="change_password_question" />
          </h2>
          <div className="modalButtons">
            <button onClick={onCloseModal}>
              <span>
                <FormattedMessage id="buttonYes" />
              </span>
            </button>
            <button onClick={onCloseModalNotification}>
              <span>
                <FormattedMessage id="buttonNo" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNotification;
