import React from "react";
import Select from "react-select";
import { customStyles } from "./customStyles";
import { languages } from "./languages";
import { LocaleSelectProps } from "./LocaleSelectorType";

const LocaleSelector = (props: LocaleSelectProps) => {
  return (
    <Select
      value={props.currentLocale}
      options={languages}
      isSearchable={false}
      styles={customStyles}
      onChange={(e) => props.changeCurrentLocale(e!)}
      formatOptionLabel={(lang) => (
        <div>
          <img
            className="inline-grid mr-2 align-top"
            src={lang.image}
            alt="CountryImage"
          />
          <span className="text-[#1A141F] font-normal">{lang.label}</span>
        </div>
      )}
    />
  );
};
export default LocaleSelector;
