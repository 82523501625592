import TrashIcon from "@skbkontur/react-icons/Trash";
import { Kebab, MenuItem, Toast } from "@skbkontur/react-ui";
import { Edit } from "@skbkontur/react-icons";
import { FormattedMessage } from "react-intl";
import { messages } from "../../../../i18n/messages";

const style = {
  alignItems: "center",
  display: "flex",
};
type KebabGroupTypeProps = {
  onShowDeleteGroup: () => void;
  onShowUpdateModal: () => void;
};

export const KebabGroup = (props: KebabGroupTypeProps) => (
  <div style={style}>
    <Kebab size={"large"}>
      <MenuItem icon={<Edit />} onClick={() => props.onShowUpdateModal()}>
        <FormattedMessage id="rename" />
      </MenuItem>
      <MenuItem icon={<TrashIcon />} onClick={() => props.onShowDeleteGroup()}>
        <FormattedMessage id="delete" />
      </MenuItem>
    </Kebab>
  </div>
);
