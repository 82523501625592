import React from "react";
import { LanguageType } from "../LocaleSelector/LocaleSelectorType";
import "../Login/Login.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginResponseDTO, UserLoginDTO } from "../../api/auth/apiClient";
import { authClient } from "../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";
import { UpdateTokens } from "../Login/LoginForm/LoginForm";

type DemoPageProps = {
  currentLocale: LanguageType;
  changeCurrentLocale: (value: LanguageType) => void;
  onChangeNotificationState: (state: NotificationState) => void;
  showNotification: NotificationState;
};
function DemoPage(props: DemoPageProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserLoginDTO>({
    defaultValues: { email: "Demo@yandex.ru", password: "Demo-1" },
  });
  const onSubmit: SubmitHandler<UserLoginDTO> = async (data) => {
    let result;
    try {
      result = await authClient.login(new UserLoginDTO(data));
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "Ошибка сервера" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    if (
      UpdateTokens(
        result as LoginResponseDTO,
        props.onChangeNotificationState,
        "logIn_message_success",
        "login_message_errors",
        "login_email_notConfirm",
        data.email,
        intl
      )
    ) {
      navigate("/");
    }
  };

  return (
    <>
      <Header
        currentLocale={props.currentLocale}
        changeCurrentLocale={props.changeCurrentLocale}
      />
      <div className="entryPage grid gap-10">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2>
              <FormattedMessage id="login_in_demo" />
            </h2>
          </div>
          <fieldset>
            <ul>
              <li>
                <label htmlFor="login">
                  <FormattedMessage id="login" />:
                </label>
                <input
                  {...register("email")}
                  type="email"
                  id="login"
                  defaultValue={"Demo@yandex.ru"}
                  readOnly={true}
                />
              </li>
              <li>
                <label htmlFor="password">
                  <FormattedMessage id="password" />:
                </label>
                <input
                  {...register("password")}
                  type="password"
                  id="password"
                  defaultValue={"Demo-1"}
                  readOnly={true}
                />
              </li>
            </ul>
          </fieldset>
          <button type="submit" className="mb-8">
            <FormattedMessage id="confirm" />
          </button>
        </form>
      </div>
    </>
  );
}

export default DemoPage;
