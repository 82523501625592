import React from "react";
import "../Login.scss";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { EntryPageViewState } from "../LoginTypes";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { LoginResponseDTO, UserLoginDTO } from "../../../api/auth/apiClient";
import { authClient, mapClient } from "../../../api/auth/AxiosInstanse";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

type PropsLoginForm = {
  rememberCheckBox: boolean;
  onRememberCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeView: (view: EntryPageViewState) => void;
  onChangeNotificationState: (state: NotificationState) => void;
};

function LoginForm(props: PropsLoginForm) {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UserLoginDTO>({
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<UserLoginDTO> = async (data) => {
    let result;
    try {
      result = await authClient.login(new UserLoginDTO(data));
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "Ошибка сервера" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    if (
      UpdateTokens(
        result as LoginResponseDTO,
        props.onChangeNotificationState,
        "logIn_message_success",
        "login_message_errors",
        "login_email_notConfirm",
        data.email,
        intl
      )
    ) {
      navigate("/");
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>
          <FormattedMessage id="login_title" />
        </h2>
        <span>
          <FormattedMessage id="login_subtitle" />
        </span>
      </div>
      <fieldset>
        <ul>
          <li>
            <label htmlFor="login">
              <FormattedMessage id="login" />
            </label>
            <input
              {...register("email", {
                required: intl.formatMessage({ id: "input_required" }),
              })}
              type="email"
              id="login"
            />
            <div className="mt-1">
              {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
            </div>
          </li>
          <li>
            <label htmlFor="password">
              <FormattedMessage id="password" />
            </label>
            <input
              {...register("password", {
                required: intl.formatMessage({ id: "input_required" }),
              })}
              type="password"
              id="password"
            />
            <div className="mt-1">
              {errors?.password && (
                <p>{errors?.password?.message || "Errors"}</p>
              )}
            </div>
          </li>
          <label className="flex justify-start items-center mb-2 mr-2">
            <input
              className="mr-2"
              checked={props.rememberCheckBox}
              onChange={props.onRememberCheckBox}
              type="checkbox"
              name="_remember_me"
            />
            <FormattedMessage id="logIn_remember" />
          </label>
        </ul>
      </fieldset>
      <button type="submit" className="w-full">
        <FormattedMessage id="entry" />
      </button>
      <div className="mt-2 pb-5 border-b">
        <a
          onClick={() => props.changeView(EntryPageViewState.PWReset)}
          href="#"
        >
          <FormattedMessage id="logIn_forgot" />
        </a>
      </div>
      <div className="mt-2">
        <span className="pr-1">
          <FormattedMessage id="login_question" />
        </span>
        <a onClick={() => props.changeView(EntryPageViewState.signUp)} href="#">
          <FormattedMessage id="logIn_create_account" />
        </a>
      </div>
    </form>
  );
}
export default LoginForm;

export function UpdateTokens(
  result: LoginResponseDTO,
  onChangeNotificationState: (state: NotificationState) => void,
  successMessage: string,
  failureMessage: string,
  notEmailConfirmed: string,
  email: string,
  intl: IntlShape
): boolean {
  if (
    result.succeeded &&
    result.tokens?.accessToken?.token &&
    result?.tokens.accessToken.expiration &&
    result.tokens?.refreshToken?.token &&
    result.tokens.refreshToken.expiration
  ) {
    localStorage.setItem("token", result.tokens?.accessToken?.token);
    localStorage.setItem("refreshToken", result.tokens?.refreshToken?.token);
    localStorage.setItem(
      "expiration",
      "" + result?.tokens.accessToken.expiration.getTime()
    );
    localStorage.setItem(
      "expirationRefreshToken",
      "" + result.tokens.refreshToken?.expiration?.getTime()
    );
    localStorage.setItem("roles", result.role!.join(","));
    /*localStorage.setItem("expiration", "" + (Date.now()+10000));*/
    /*onChangeNotificationState({
      isShow: true,
      type: NotificationType.info,
      message: intl.formatMessage({ id: successMessage }),
    });*/
    return true;
  } else if (result.isEmailConfirmed !== null && !result.isEmailConfirmed) {
    mapClient.sendConfirmationEmail(email);
    onChangeNotificationState({
      isShow: true,
      type: NotificationType.error,
      message: intl.formatMessage({ id: notEmailConfirmed }),
    });
  } else {
    onChangeNotificationState({
      isShow: true,
      type: NotificationType.error,
      message: intl.formatMessage({ id: failureMessage }),
    });
  }
  return false;
}
