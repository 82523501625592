import React from "react";
import "./EmailVerificationPage.scss";
import { LanguageType } from "../LocaleSelector/LocaleSelectorType";
import Header from "../Header/Header";
import { ReactComponent as Verification } from "./svg/emailVerificationIcon.svg";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

type EmailVerificationPageProps = {
  currentLocale: LanguageType;
  changeCurrentLocale: (value: LanguageType) => void;
};
function EmailVerificationPage(props: EmailVerificationPageProps) {
  const navigate = useNavigate();
  const loginUser = () => {
    navigate("/login");
  };
  return (
    <div className="verification_page">
      <Header
        currentLocale={props.currentLocale}
        changeCurrentLocale={props.changeCurrentLocale}
      />
      <div className="verification_container">
        <div>
          <div className="verification_icon">
            <Verification />
          </div>
          <div className="verification_title">
            <h2>
              <FormattedMessage id="verification_title" />
            </h2>
          </div>
          <div className="verification_subtitle">
            <p>
              <FormattedMessage id="verification_subtitle" />
            </p>
          </div>
          <div className="flex justify-center mt-9">
            <button onClick={loginUser} type="button" className="btn">
              <span>
                <FormattedMessage id="verification" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationPage;
