import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as BtnEditor } from "./svg/btnEditor.svg";

type PropsButtonEditor = {
  onChangeDisabled: (isDisabled: boolean) => void;
  isDisabled: boolean;
};
function ButtonEdit(props: PropsButtonEditor) {
  function onHandleClick() {
    props.onChangeDisabled(!props.isDisabled);
  }
  return (
    <button
      type="button"
      onClick={onHandleClick}
      className={`btn ml-5 ${!props.isDisabled ? "active" : ""}`}
    >
      <BtnEditor />
      {!props.isDisabled ? (
        <span className="pl-2.5">
          <FormattedMessage id="cancel" />
        </span>
      ) : (
        <span className="pl-2.5">
          <FormattedMessage id="edit" />
        </span>
      )}
    </button>
  );
}

export default ButtonEdit;
