import { MapContainer as LeafletMapContainer, TileLayer } from "react-leaflet";
import PlacemarkList from "./PlacemarkList/PlacemarkList";
import { MapContainerProps } from "./MapContainerTypes";
import "./MapContainer.scss";

const CustomMapContainer: React.FC<MapContainerProps> = (props) => {
  return (
    <div className="map-container-wrapper">
      <LeafletMapContainer
        center={[
          props.coordinates.latitudeCenter,
          props.coordinates.longitudeCenter,
        ]}
        zoom={4.5}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <PlacemarkList carInfo={props.carInfo} />
      </LeafletMapContainer>
    </div>
  );
};

export default CustomMapContainer;
