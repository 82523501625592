import React from "react";
import "./Modal.scss";
import { FormattedMessage } from "react-intl";

type ModalProps = {
  onClickStayPage: (showModal: boolean) => void;
  onClosePage: () => void;
};
function Modal(props: ModalProps) {
  return (
    <div className="modal_overlay">
      <div className="modal_container">
        <div className="modal_form">
          <div className="modal_header">
            <div>
              <h2>
                <FormattedMessage id="transport_info" />
              </h2>
            </div>
            <img
              onClick={() => props.onClickStayPage(false)}
              src="/image/btn-remove/btn-remove.svg"
              alt="Close modal"
            />
          </div>
          <div className="modal_body">
            <p>
              <FormattedMessage id="transport_question" />
            </p>
            <p>
              <FormattedMessage id="transport_warning" />
            </p>
          </div>
          <div className="flex mt-5">
            <div className="mr-7">
              <button
                onClick={props.onClosePage}
                type="button"
                className="cancel ml-auto"
              >
                <span>
                  <FormattedMessage id="leave_page" />
                </span>
              </button>
            </div>
            <button
              onClick={() => props.onClickStayPage(false)}
              type="button"
              className="btn"
            >
              <span>
                <FormattedMessage id="stay_page" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
