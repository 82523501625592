import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "@skbkontur/react-ui";
import { StType } from "./AdminPage";
import "./SortedByStatus.scss";
import { UploadStatus } from "../../api/auth/apiClient";

type SortedByStatusProps = {
  handleFilterByStatus: (statuses: UploadStatus[]) => void;
  setSelectedUploadStatuses: (statuses: UploadStatus[]) => void;
  selectedUploadStatuses: UploadStatus[];
  getResults: () => void;
};

function SortedByStatus(props: SortedByStatusProps) {
  const handleCheckboxChange = (status: UploadStatus, checked: boolean) => {
    const newSelectedUploadStatuses =
      props.selectedUploadStatuses.includes(status) && !checked
        ? props.selectedUploadStatuses.filter((s) => s !== status)
        : [...props.selectedUploadStatuses, status];
    props.setSelectedUploadStatuses(newSelectedUploadStatuses);
    props.handleFilterByStatus(newSelectedUploadStatuses);
  };
  function usePrevious(value: UploadStatus[]) {
    const ref = useRef<UploadStatus[]>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevValue = usePrevious(props.selectedUploadStatuses ?? []);
  useEffect(() => {
    if (prevValue !== props.selectedUploadStatuses) {
      handleCheckboxChange(
        props.selectedUploadStatuses[
          (UploadStatus.Error, UploadStatus.Success, UploadStatus.Uploading)
        ],
        true
      );
    }
  }, [
    props.selectedUploadStatuses.includes(UploadStatus.Error),
    props.selectedUploadStatuses.includes(UploadStatus.Success),
    props.selectedUploadStatuses.includes(UploadStatus.Uploading),
  ]);

  return (
    <div className="flex ">
      <div className="sortedModal">
        {StType.map((st) => (
          <Checkbox
            key={st.value}
            checked={props.selectedUploadStatuses.includes(st.value)}
            onValueChange={(checked) => handleCheckboxChange(st.value, checked)}
          >
            {st.label}
          </Checkbox>
        ))}
      </div>
    </div>
  );
}

export default SortedByStatus;
