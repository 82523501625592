import React, { useState } from "react";
import "./Menu.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as Avatar } from "./svgs/Avatar.svg";
import { MenuTypeProps } from "./MenuTypes";
import { ReactComponent as User } from "./svgs/user.svg";
import { ReactComponent as Settings } from "./svgs/setting.svg";
import { ReactComponent as Mouse } from "./svgs/mouse.svg";
import { ReactComponent as System } from "./svgs/system.svg";
import { ReactComponent as Security } from "./svgs/security.svg";
import { ReactComponent as Arrow } from "./svgs/arrow.svg";
import { FormattedMessage, useIntl } from "react-intl";

type NavigationsType = {
  id: number;
  title: string;
  Image: React.FC<React.SVGProps<SVGSVGElement>>;
  accessRoles: Array<UserRole>;
};
export enum UserRole {
  SystemAdmin = "systemadmin",
  Admin = "admin",
  Editor = "editor",
  Viewer = "viewer",
}

function Menu(props: MenuTypeProps) {
  const roles = localStorage.getItem("roles")?.split(",");
  const intl = useIntl();
  const navigate = useNavigate();
  const currentUserRoles = roles?.map((role) => {
    switch (role.toLowerCase()) {
      case "systemadmin":
        return UserRole.SystemAdmin;
      case "admin":
        return UserRole.Admin;
      case "editor":
        return UserRole.Editor;
      case "viewer":
        return UserRole.Viewer;
      default:
        return UserRole.Viewer;
    }
  });
  const navigations: Array<NavigationsType> = [
    {
      id: 1,
      title: intl.formatMessage({ id: "users" }),
      Image: User,
      accessRoles: [UserRole.Admin, UserRole.SystemAdmin],
    },
    {
      id: 2,
      title: intl.formatMessage({ id: "administration" }),
      Image: Settings,
      accessRoles: [UserRole.Admin, UserRole.SystemAdmin],
    },
    {
      id: 3,
      title: intl.formatMessage({ id: "vehicles_list" }),
      Image: Mouse,
      accessRoles: [
        UserRole.Admin,
        UserRole.SystemAdmin,
        UserRole.Editor,
        UserRole.Viewer,
      ],
    },
    {
      id: 4,
      title: intl.formatMessage({ id: "system_list" }),
      Image: System,
      accessRoles: [UserRole.Admin, UserRole.SystemAdmin],
    },
    {
      id: 5,
      title: intl.formatMessage({ id: "change_password_menu" }),
      Image: Security,
      accessRoles: [UserRole.Admin, UserRole.SystemAdmin],
    },
    {
      id: 6,
      title: intl.formatMessage({ id: "log_out" }),
      Image: Arrow,
      accessRoles: [
        UserRole.Admin,
        UserRole.Viewer,
        UserRole.SystemAdmin,
        UserRole.Editor,
      ],
    },
  ];
  const [activeItem, setIsActive] = useState(0);
  function onChangeActive(id: number) {
    setIsActive(id);
    switch (id) {
      case 1:
        {
          props.onShowUsersPage(true);
        }
        break;
      case 2:
        {
          props.onShowAdminPage(true);
        }
        break;
      case 3:
        {
          props.onShowTransportList(true);
        }
        break;
      case 4:
        {
          props.onShowSystemPage(true);
        }
        break;
      case 5:
        {
          props.onShowChangePassword(true);
        }
        break;
      case 6: {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiration");
        localStorage.removeItem("expirationRefreshToken");
        navigate("/login");
      }
    }
  }

  return (
    <nav className="menu">
      <div className="avatarUser flex items-center justify-center">
        <Avatar alt="Avatar" />
      </div>
      <div className="userName flex flex-col items-center justify-center">
        <p>
          <FormattedMessage id="user_name" />
        </p>
        <p>
          <FormattedMessage id="user_surname" />
        </p>
        <p className="job">
          <FormattedMessage id="user_role" />
        </p>
      </div>
      {navigations
        .filter(
          (n) =>
            n.accessRoles.filter((role) => currentUserRoles?.includes(role))
              .length > 0
        )
        .map(({ id, title, Image }) => (
          <div className="navigation" key={id}>
            <ul>
              <li onClick={() => onChangeActive(id)}>
                <NavLink to="/">
                  <Image className={activeItem === id ? "active" : ""} />
                  <span className={activeItem === id ? "active" : ""}>
                    {title}
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        ))}
    </nav>
  );
}
export default Menu;
