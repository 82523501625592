export const customStyles = {
  container: (base: any) => ({
    ...base,
    maxWidth: "100%",
  }),
  control: (base: any) => ({
    ...base,
    width: 77,
    minHeight: 20,
    border: "1px solid #E5E0EB",
    borderRadius: 2,
    background: "#FFFFFF",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
    justifyContent: "center",
  }),

  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 0,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: 0,
  }),
  option: (base: any) => ({
    ...base,
    padding: 0,
  }),
};
