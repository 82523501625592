import React, { useEffect, useRef, useState } from "react";
import "./AdmiinPage.scss";
import "../../index.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { ReactComponent as InputSearch } from "./svg/InputSearch.svg";
import { ReactComponent as Warning } from "./svg/attention.svg";
import { ReactComponent as SortStatus } from "./svg/sortStatus.svg";
import { mapClient } from "../../api/auth/AxiosInstanse";
import {
  Firmware,
  StartCompanyUpdateSessionDTO,
  UpdateResultDTO,
  UploadStatus,
} from "../../api/auth/apiClient";
import {
  NotificationState,
  NotificationType,
} from "../Notification/notificationTypes";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "./stylesAdminPage";
import { ReactComponent as LoadingBtn } from "./svg/Icon_loading.svg";
import { stylesForSelectFirmWare } from "./stylesForSelectFirmWare";
import Notification from "../Notification/Notification";
import SortedByStatus from "./SortedByStatus";
import { Group, DatePicker } from "@skbkontur/react-ui";

type AdminPageProps = {
  showAdminPage: boolean;
  onShowAdminPage: (showUsersPage: boolean) => void;
  onShowTransportEditorList: (showTransportEditor: boolean) => void;
  showTransportEditor: boolean;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  onChange: (value: string) => void;
};

export const StType = [
  {
    label: `\u{1F534} Error`,
    value: UploadStatus.Error,
  },
  {
    label: `\u{1F7E2} Success`,
    value: UploadStatus.Success,
  },
  {
    label: `\u{1F535} Uploading`,
    value: UploadStatus.Uploading,
  },
];

function AdminPage(props: AdminPageProps) {
  const intl = useIntl();
  const [updateResults, setUpdateResults] = useState<Array<UpdateResultDTO>>();
  const [softWareVersion, setSoftWareVersion] = useState<Array<Firmware>>();
  const [selectedRows, setSelectedRows] = useState<UpdateResultDTO[]>([]);
  const [selectedFirmware, setSelectedFirmware] = useState<Firmware>();
  const [isNewFirmware, setIsNewFirmware] = useState<boolean>();
  const [filterUpdateResults, setFilterUpdateResults] = useState(updateResults);
  const [showModalSortedByStatus, setShowModalSortedByStatus] = useState(false);
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [searchString, setSearchString] = useState("");
  const [selectedUploadStatuses, setSelectedUploadStatuses] = useState<
    UploadStatus[]
  >([UploadStatus.Error, UploadStatus.Success, UploadStatus.Uploading]);

  async function getResults() {
    try {
      const firstDateSplit = firstDate?.split(".");
      const lastDateSplit = lastDate?.split(".");
      const firstDateParse =
        firstDateSplit?.length === 3
          ? new Date(
              Date.UTC(
                Number(firstDateSplit[2]),
                Number(firstDateSplit[1]) - 1,
                Number(firstDateSplit[0])
              )
            )
          : undefined;
      const lastDateParse =
        lastDateSplit?.length === 3
          ? new Date(
              Date.UTC(
                Number(lastDateSplit[2]),
                Number(lastDateSplit[1]) - 1,
                Number(lastDateSplit[0])
              )
            )
          : undefined;

      const response = await mapClient.getUpdateResults(
        searchString,
        firstDateParse,
        lastDateParse
      );
      setUpdateResults(response.data ?? []);
      setFilterUpdateResults(response.data ?? []);
      const softWareVersion = await mapClient.getFirmwares();
      setSoftWareVersion(softWareVersion.data);
      setSelectedFirmware(
        softWareVersion.data?.find((s) => s.id === selectedFirmware?.id) ??
          softWareVersion.data?.[0]
      );
      setIsNewFirmware(
        softWareVersion.data?.find((s) => s.isNew === false) !== undefined
      );
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "data_receiving_error" }),
      });
    }
  }
  useEffect(() => {
    getResults();
  }, []);

  const columns: TableColumn<UpdateResultDTO>[] = [
    {
      name: intl.formatMessage({ id: "transport_name_title" }),
      selector: (result) => result.name ?? "-",
      sortable: true,
      grow: 4,
      wrap: true,
    },
    {
      name: intl.formatMessage({ id: "transport_uvi_title" }),
      selector: (result) => result.uvi ?? "-",
      sortable: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "transport_software_version_title" }),
      selector: (result) =>
        softWareVersion?.find((r) => r.id === result.uploadedFirmwareId)
          ?.name ?? "-",
      sortable: true,
      grow: 4,
      wrap: true,
      compact: true,
    },
    {
      name: intl.formatMessage({ id: "transport_status_title" }),
      selector: (result) =>
        StType.find((s) => s.value === result?.status)?.label ?? "-",
      sortable: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "transport_date_time_title" }),
      selector: (result) =>
        result.startDate?.toLocaleString("ru", {
          timeZone: "UTC",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
      grow: 4,
      sortable: true,
    },
  ];
  const onChange = (value: string) => {
    props.onChange(value);
  };

  async function onUpdateSoftwareVersion() {
    try {
      await mapClient.startCompanyUpdateSession({
        firmwareId: selectedFirmware?.id ?? "",
        uvIs: selectedRows.map((r) => r.uvi!),
        ignoreUpdated: false,
      } as StartCompanyUpdateSessionDTO);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "update_success" }),
      });
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "data_receiving_error" }),
      });
    }
  }

  function usePrevious(value: string) {
    const ref = useRef<string>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevValue = usePrevious(searchString);
  useEffect(() => {
    if (prevValue !== searchString) {
      getResults();
    }
  }, [searchString]);

  function handleClearChoice() {
    setSearchString("");
    setFirstDate("");
    setLastDate("");
    setSelectedUploadStatuses([
      UploadStatus.Error,
      UploadStatus.Success,
      UploadStatus.Uploading,
    ]);
  }

  const handleFilterByStatus = (statuses: UploadStatus[]) => {
    const newFilter = updateResults?.filter((result) => {
      return statuses.includes(result.status!);
    });
    setFilterUpdateResults(newFilter);
  };

  return (
    <div className="adminPageList">
      <div className="adminPageModuleContainer">
        <div className="adminPageHeader">
          <h2>
            <FormattedMessage id="administration" />
          </h2>
        </div>
        <div className="adminPageBlockContainer">
          <div className="adminSetting">
            <div className="softwareVersionBlock">
              <div className="flex items-end">
                <div className="mr-5">
                  <span>
                    <FormattedMessage id="softwareVersion" />
                  </span>
                  <Select
                    options={softWareVersion?.map((s) => {
                      return { value: s.id, label: s.name };
                    })}
                    value={
                      softWareVersion
                        ?.map((s) => {
                          return { value: s.id, label: s.name };
                        })
                        .find((s) => s.value === selectedFirmware?.id) ??
                      undefined
                    }
                    onChange={(value) => onChange(value?.value ?? "")}
                    styles={stylesForSelectFirmWare}
                    isSearchable={false}
                    isClearable={false}
                    formatOptionLabel={(softWareVersion) => (
                      <div>
                        {isNewFirmware ? (
                          <div className="flex justify-between">
                            {softWareVersion.label}
                            <Warning className="inline-grid align-top" />
                          </div>
                        ) : null}
                      </div>
                    )}
                  />
                </div>
                <button className="btn" onClick={onUpdateSoftwareVersion}>
                  <LoadingBtn />
                  <span className="ml-2">
                    <FormattedMessage id="button_upload" />
                  </span>
                </button>
              </div>
              <div className="adminBlockSearch mt-3">
                <div className="admin_search flex items-center">
                  <div className="adminImage">
                    <InputSearch />
                  </div>
                  <input
                    name={"searchString"}
                    type="text"
                    className="searchInputAdmin"
                    placeholder={intl.formatMessage({ id: "search_CanId" })}
                    onChange={(e) => setSearchString(e.target.value)}
                    value={searchString}
                  />
                </div>
                <div className="flex items-center mt-1.5">
                  <span className="searchByDate">
                    <FormattedMessage id="search_by_date" />
                  </span>
                  <div className="ml-2">
                    <Group>
                      <DatePicker
                        onValueChange={setFirstDate}
                        value={firstDate}
                        width={120}
                      />
                    </Group>
                  </div>
                  <div className="ml-2">
                    <Group>
                      <DatePicker
                        onValueChange={setLastDate}
                        value={lastDate}
                        width={120}
                      />
                    </Group>
                  </div>
                  <div className="ml-5">
                    <button
                      type="button"
                      className="btn_search"
                      onClick={() => getResults()}
                    >
                      <span>
                        <FormattedMessage id="search" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-start items-center mt-4">
                <div
                  className="cleanChoice cursor-pointer"
                  onClick={handleClearChoice}
                >
                  <span>
                    <FormattedMessage id="clear_choice" />
                  </span>
                </div>
                <div className="aminBlockSorted flex items-center ml-3">
                  <div className="flex items-start">
                    <span className="showOnly">
                      <FormattedMessage id="show_only_title" />
                    </span>
                    <SortStatus
                      className="ml-2 cursor-pointer"
                      onClick={() =>
                        setShowModalSortedByStatus(!showModalSortedByStatus)
                      }
                    />
                  </div>
                </div>
              </div>

              {showModalSortedByStatus ? (
                <div>
                  <SortedByStatus
                    handleFilterByStatus={handleFilterByStatus}
                    selectedUploadStatuses={selectedUploadStatuses}
                    setSelectedUploadStatuses={setSelectedUploadStatuses}
                    getResults={getResults}
                  />
                </div>
              ) : null}
              <div className="tableBlock mt-5">
                <DataTable
                  columns={columns}
                  data={filterUpdateResults!}
                  noDataComponent={intl.formatMessage({
                    id: "empty_table",
                  })}
                  selectableRows={true}
                  selectableRowsHighlight={true}
                  selectableRowsVisibleOnly={true}
                  fixedHeader
                  striped
                  fixedHeaderScrollHeight="350px"
                  pointerOnHover
                  persistTableHead
                  customStyles={customStyles}
                  highlightOnHover
                  onSelectedRowsChange={(row) => {
                    setSelectedRows(row.selectedRows);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification
        showNotification={props.showNotification}
        onChangeNotificationState={props.onChangeNotificationState}
      />
    </div>
  );
}

export default AdminPage;
