import React from "react";
import "./System.scss";
import { FormattedMessage, useIntl } from "react-intl";

function System() {
  return (
    <div>
      <h2>
        <FormattedMessage id="system_list" />
      </h2>
    </div>
  );
}

export default System;
