import React from "react";
import "./Notification.scss";
import { NotificationType, NotificationTypeProps } from "./notificationTypes";
import { ReactComponent as IconFail } from "./svg/Icon_fail.svg";
import { ReactComponent as IconWarning } from "./svg/Icon_warning.svg";
import { ReactComponent as IconSuccess } from "./svg/Icon_success.svg";
import { ReactComponent as IconClose } from "./svg/close_notification.svg";

function Notification(props: NotificationTypeProps) {
  if (!props.showNotification.isShow) {
    return null;
  }
  const onCloseNotification = () => {
    props.onChangeNotificationState({
      isShow: false,
      type: NotificationType.info,
      message: "",
    });
  };

  switch (props.showNotification.type) {
    case NotificationType.error: {
      return (
        <div className="notification bg-[#EC4C47]">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconFail />
          </div>
          <div>
            <span>{props.showNotification.message}</span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
    case NotificationType.info: {
      return (
        <div className="notification bg-[#35A936]">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconSuccess />
          </div>
          <div>
            <span>{props.showNotification.message}</span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
    case NotificationType.warning: {
      return (
        <div className="notification_warning">
          <div className="white_line"></div>
          <div className="mr-3 ml-3">
            <IconWarning />
          </div>
          <div>
            <span className="text_warning">
              {props.showNotification.message}
            </span>
          </div>
          <div
            onClick={onCloseNotification}
            className="ml-2 mr-4 cursor-pointer"
          >
            <IconClose />
          </div>
        </div>
      );
    }
  }
}
export default Notification;
