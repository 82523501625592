import React, { useState } from "react";
import "./TransportList.scss";
import { ReactComponent as Plus } from "./svg/Plus.svg";
import { ReactComponent as BusIcon } from "./svg/bus.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { TransportListProps } from "./TransportListTypes";
import { Gapped } from "@skbkontur/react-ui";
import { Card } from "./KebabMenu";
import { mapClient } from "../../api/auth/AxiosInstanse";
import ModalNotification from "../CreateTransport/Modal_notification/ModalNotification";
import { NotificationType } from "../Notification/notificationTypes";
import Notification from "../Notification/Notification";

function TransportList(props: TransportListProps) {
  const intl = useIntl();
  const [showDeleteTransport, setShowDeleteTransport] = useState(false);
  const [carIdForDelete, setCarIdForDelete] = useState<string>();
  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filtredTransports = [
      ...props.carInfo.filter((c) =>
        c.name.toLowerCase().includes(event.target.value.toLowerCase())
      ),
    ];
    props.setSearchTransport(filtredTransports);
  };
  function addNewTransport() {
    props.onClickButtonAddNewTransport(true);
    props.onShowCreateTransport(true);
  }
  async function onChooseShowEditorList(id: string) {
    await props.onChooseTransport(id);
    props.onShowTransportEditorList(true);
    props.onClickButtonAddNewTransport(false);
  }
  function onCloseTransportList() {
    props.onShowTransportList(false);
    props.onShowTransportEditorList(false);
  }
  function onShowDeleteFunctionModal() {
    setShowDeleteTransport(true);
  }
  async function deleteTransport(id: string) {
    setShowDeleteTransport(false);
    if (id === props.activeTransport?.id) {
      props.onShowTransportEditorList(false);
    }
    try {
      await mapClient.deleteVehicle(id);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "deleteTransportSuccess" }),
      });
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "deleteTransportError" }),
      });
    }
    await props.getTransport();
  }
  return (
    <>
      {showDeleteTransport ? (
        <div>
          <ModalNotification
            modalQuestion={intl.formatMessage({
              id: "deleteTransportQuestion",
            })}
            onYesModalActions={() => setShowDeleteTransport(false)}
            onNoModalActions={() => {
              if (carIdForDelete) {
                deleteTransport(carIdForDelete);
              }
            }}
          />
        </div>
      ) : null}
      <div className="transportMenu">
        <div className="transportMenuHeader flex">
          <h2>
            <FormattedMessage id="transport_list" />
          </h2>
          <img
            onClick={onCloseTransportList}
            className="btnRemove"
            src="/image/btn-remove/btn-remove.svg"
            alt="Close"
          />
        </div>
        <div className="searchTransport flex mt-3">
          <img
            className="searchImage"
            src="/image/search/search.svg"
            alt="Search"
          />
          <input
            onChange={onChangeSearchInput}
            placeholder={intl.formatMessage({ id: "transportSearch" })}
          />
        </div>
        <div className="transportFunction">
          <div
            onClick={addNewTransport}
            className={`transportButton ${
              props.transportActiveClick ? "active" : ""
            } flex flex-wrap items-center cursor-pointer `}
          >
            <Plus />
            <span>
              <FormattedMessage id="add_new" />
            </span>
          </div>
          <ul>
            {props.searchTransport.map((car) => (
              <li
                className={`flex flex-wrap items-center cursor-pointer ${
                  props.activeTransport?.id === car.id ? "active" : ""
                }`}
                onClick={() => onChooseShowEditorList(car.id!)}
                key={car.id}
              >
                <div className="flex items-center">
                  <div className="pl-2.5">
                    <BusIcon />
                  </div>

                  <span className="transportName">{car.name}</span>
                </div>
                <div className="flex ml-auto items-center">
                  {car.coordinates && (
                    <div
                      className="mr-2.5"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (car.coordinates) {
                          props.setMapCenter({
                            latitudeCenter: car.coordinates?.latitude || 0,
                            longitudeCenter: car.coordinates?.longitude || 0,
                            zoom: 10,
                          });
                        }
                      }}
                    >
                      <img src="/image/location.svg" alt="Location" />
                    </div>
                  )}

                  <div
                    className="mr-2"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Gapped>
                      <Card
                        onShowDeleteFunctionModal={() => {
                          setCarIdForDelete(car.id);
                          onShowDeleteFunctionModal();
                        }}
                      />
                    </Gapped>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Notification
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      </div>
    </>
  );
}

export default TransportList;
