import React, { useState } from "react";
import Login from "./components/Login/Login";
import { IntlProvider } from "react-intl";
import { messages } from "./i18n/messages";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./hoc/PrivateRoute";
import MainPage from "./components/MainPage/MainPage";
import { languages } from "./components/LocaleSelector/languages";
import { LanguageType } from "./components/LocaleSelector/LocaleSelectorType";
import {
  NotificationState,
  NotificationType,
} from "./components/Notification/notificationTypes";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import EmailVerificationPage from "./components/VerificationPage/EmailVerificationPage";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import DemoPage from "./components/Demo/DemoPage";

function App() {
  const [currentLocale, setCurrentLocale] = useState<LanguageType>(
    languages[0]
  );
  const [showNotification, setShowNotification] = useState<NotificationState>({
    isShow: false,
    type: NotificationType.error,
    message: "",
  });
  const onChange = (value: string) => {
    console.log(value);
  };

  function changeCurrentLocale(value: LanguageType) {
    setCurrentLocale(value);
  }
  async function onChangeNotificationState(state: NotificationState) {
    setShowNotification(state);
    await new Promise((r) => setTimeout(r, 5000));
    setShowNotification({
      isShow: false,
      type: NotificationType.info,
      message: "",
    });
  }
  return (
    <IntlProvider
      messages={messages[currentLocale.value]}
      locale={currentLocale.value}
      defaultLocale={"RU"}
    >
      <Routes>
        <Route
          path="/verifyemail"
          element={
            <EmailVerificationPage
              changeCurrentLocale={changeCurrentLocale}
              currentLocale={currentLocale}
            />
          }
        />
        <Route
          path="/reset-password"
          element={
            <ResetPassword
              onChangeNotificationState={onChangeNotificationState}
            />
          }
        />
        <Route
          path="/demo"
          element={
            <DemoPage
              changeCurrentLocale={changeCurrentLocale}
              currentLocale={currentLocale}
              onChangeNotificationState={onChangeNotificationState}
              showNotification={showNotification}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Login
              changeCurrentLocale={changeCurrentLocale}
              currentLocale={currentLocale}
              onChangeNotificationState={onChangeNotificationState}
              showNotification={showNotification}
            />
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute props={{ onChangeNotificationState }}>
              <MainPage
                onChangeNotificationState={onChangeNotificationState}
                showNotification={showNotification}
                changeCurrentLocale={changeCurrentLocale}
                currentLocale={currentLocale}
                onChange={onChange}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <ErrorPage
              changeCurrentLocale={changeCurrentLocale}
              currentLocale={currentLocale}
            />
          }
        />
      </Routes>
    </IntlProvider>
  );
}
export default App;
