import React from "react";
import "./UserPage.scss";
import { ReactComponent as Remove } from "./svg/btn-remove.svg";
import { useIntl } from "react-intl";

type FilterComponentProps = {
  filterText: string;
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
};

export function FilterComponent(props: FilterComponentProps) {
  const intl = useIntl();

  return (
    <div className="searchBlock">
      <input
        type="text"
        value={props.filterText}
        onChange={props.onFilter}
        placeholder={intl.formatMessage({ id: "tableSearch" })}
      />
      <button className="btn-clear" type="button" onClick={props.onClear}>
        <Remove />
      </button>
    </div>
  );
}
