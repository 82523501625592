import React, { useState } from "react";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import "./MainPage.scss";
import Transport from "../Transport/Transport";
import { MainPageProps } from "./MainPageTypes";
import AdminPage from "../AdminPage/AdminPage";
import System from "../SystemPage/System";
import ChangePassword from "../ChangePasswordForm/ChangePassword";
import UserPage from "../UserPage/UserPage";

function MainPage(props: MainPageProps) {
  const [showTransportList, setShowTransportList] = useState(false);
  const [showAdminPage, setShowAdminPage] = useState(false);
  const [showUsersPage, setShowUsersPage] = useState(false);
  const [showSystem, setShowSystem] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showTransportEditor, setShowTransportEditor] = useState(false);
  function onShowTransportList(showTransportList: boolean) {
    setShowTransportList(showTransportList);
    if (showTransportList) {
      onShowAdminPage(false);
      onShowUsersPage(false);
      onShowSystemPage(false);
      onShowChangePassword(false);
    }
  }
  function onShowAdminPage(showAdminPage: boolean) {
    setShowAdminPage(showAdminPage);
    if (showAdminPage) {
      onShowTransportList(false);
      onShowUsersPage(false);
      onShowSystemPage(false);
      onShowChangePassword(false);
    }
  }
  function onShowUsersPage(showUsersPage: boolean) {
    setShowUsersPage(showUsersPage);
    if (showUsersPage) {
      onShowTransportList(false);
      onShowAdminPage(false);
      onShowSystemPage(false);
      onShowChangePassword(false);
    }
  }
  function onShowSystemPage(showSystem: boolean) {
    setShowSystem(showSystem);
    if (showSystem) {
      onShowTransportList(false);
      onShowAdminPage(false);
      onShowUsersPage(false);
      onShowChangePassword(false);
    }
  }
  function onShowChangePassword(showModalChangePassword: boolean) {
    setShowModalChangePassword(showModalChangePassword);
    if (showModalChangePassword) {
      onShowTransportList(false);
      onShowAdminPage(false);
      onShowUsersPage(false);
      onShowSystemPage(false);
    }
  }
  function onShowTransportEditorList(showTransportEditor: boolean) {
    setShowTransportEditor(showTransportEditor);
  }
  return (
    <div className="wrapper">
      <Header
        currentLocale={props.currentLocale}
        changeCurrentLocale={props.changeCurrentLocale}
      />
      <Menu
        onShowTransportList={onShowTransportList}
        onShowAdminPage={onShowAdminPage}
        onShowUsersPage={onShowUsersPage}
        onShowSystemPage={onShowSystemPage}
        onShowChangePassword={onShowChangePassword}
      />
      {!showAdminPage && !showUsersPage && !showSystem ? (
        <Transport
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
          showTransportList={showTransportList}
          onShowTransportList={onShowTransportList}
          onShowTransportEditorList={onShowTransportEditorList}
          showTransportEditor={showTransportEditor}
        />
      ) : null}
      {showAdminPage ? (
        <AdminPage
          showAdminPage={showAdminPage}
          onShowAdminPage={onShowAdminPage}
          onShowTransportEditorList={onShowTransportEditorList}
          showTransportEditor={showTransportEditor}
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
          onChange={props.onChange}
        />
      ) : null}
      {showUsersPage ? (
        <UserPage
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      ) : null}
      {showSystem ? <System /> : null}
      {showModalChangePassword ? (
        <ChangePassword
          onChangeNotificationState={props.onChangeNotificationState}
          onShowChangePassword={onShowChangePassword}
        />
      ) : null}
    </div>
  );
}

export default MainPage;
