import "../../../../styles/variables.scss";

export const customStylesFromValue = {
  control: (base: any) => ({
    ...base,
    width: 306,
    height: 44,
    border: "$Border2",
    borderRadius: 4,
    background: "$BgColor",
    cursor: "pointer",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
    color: "#000",
  }),
  option: (base: any) => ({
    ...base,
    cursor: "pointer",
    padding: "5px 10px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    fontWeight: 400,
    fontSize: 14,
  }),
};
