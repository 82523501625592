import { DataOrder, DataSigned, DataType } from "../../../api/auth/apiClient";

export const optionsDataType = [
  { label: "bit", value: DataType.Bit },
  { label: "byte", value: DataType.Byte },
  { label: "2-Byte", value: DataType.TwoByte },
];

export const DataCollectionPeriod = [
  { label: "1 секунда", value: 1 },
  { label: "5 секунд", value: 5 },
  { label: "10 секунд", value: 10 },
  { label: "20 секунд", value: 20 },
  { label: "30 секунд", value: 30 },
  { label: "1 минуту", value: 60 },
  { label: "10 минут", value: 600 },
  { label: "30 минут", value: 1800 },
  { label: "1 час", value: 3600 },
  { label: "4 часа", value: 1400 },
  { label: "12 часов", value: 43200 },
  { label: "24 часа", value: 86400 },
];

export const DataCanInterFaceSpeed = [
  { label: "10 Kbit", value: 10 },
  { label: "20 Kbit", value: 20 },
  { label: "50 Kbit", value: 50 },
  { label: "100 Kbit", value: 100 },
  { label: "125 Kbit", value: 125 },
  { label: "250 Kbit", value: 250 },
  { label: "500 Kbit", value: 500 },
  { label: "800 Kbit", value: 800 },
  { label: "1 Mbit", value: 1000 },
];

export const DataRoamingPeriod = [
  { label: "Запрещен", value: 1 },
  { label: "Только для СМС", value: 2 },
  { label: "Разрешен", value: 3 },
];

export const DataSendingPeriod = [
  { label: "1 секунда", value: 1 },
  { label: "30 секунд", value: 30 },
  { label: "1 минуту", value: 60 },
  { label: "10 минут", value: 600 },
  { label: "30 минут", value: 1800 },
  { label: "1 час", value: 3600 },
  { label: "4 часа", value: 1400 },
  { label: "12 часов", value: 43200 },
  { label: "24 часа", value: 86400 },
];

export const optionsCanType = [
  { label: "Standard", value: 1 },
  { label: "J1939", value: 2 },
];

export const optionsDataByte = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
];

export const optionsDataBit = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
];

export const optionsDataSigned = [
  { label: "signed", value: DataSigned.Signed },
  { label: "unsigned", value: DataSigned.Unsigned },
];

export const optionsDataOrder = [
  { label: "L...H", value: DataOrder.LH },
  { label: "H...L", value: DataOrder.HL },
];
