import "../../styles/variables.scss";
export const customStyles = {
  headRow: {
    style: {
      backgroundColor: "none",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#FFF",
      fontSize: "14px",
    },
  },
  rows: {
    style: {
      background: "$Color13",
      minHeight: "60px",
    },
  },
  cells: {
    style: {
      fontColor: "#1A141F",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: "14px",
    },
  },
  pagination: {
    style: {
      minHeight: "50px",
      fontWeight: "400",
      fontSize: "14px",
    },
    pageButtonsStyle: {
      transition: "0.2s",
    },
  },
};
