import React from "react";
import Header from "../Header/Header";
import { LanguageType } from "../LocaleSelector/LocaleSelectorType";
import "./ErrorPage.scss";
import { ReactComponent as Error } from "./svg/Group.svg";
import { FormattedMessage } from "react-intl";

type ErrorPageProps = {
  currentLocale: LanguageType;
  changeCurrentLocale: (value: LanguageType) => void;
};

function ErrorPage(props: ErrorPageProps) {
  return (
    <div className="wrapper_page">
      <Header
        currentLocale={props.currentLocale}
        changeCurrentLocale={props.changeCurrentLocale}
      />
      <div className="page_container">
        <div>
          <div className="page_title">
            <h2>
              <FormattedMessage id="error_page_title" />
            </h2>
          </div>
          <div className="page_item">
            <Error />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
