export type NotificationTypeProps = {
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
};

export enum NotificationType {
  info,
  error,
  warning,
}

export interface NotificationState {
  isShow: boolean;
  type: NotificationType;
  message: string;
}
